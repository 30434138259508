.interactive-prgs {
  background-color: #ffffff;

  .prgs-blue {
    background-color: #0b099c;
    width: 80%;
    height: 10px;
  }

  .prgs-red {
    background-color: #d0302b;
    width: 80%;
    height: 10px;
  }

  .prgs-orange {
    background-color: #f7ba44;
    width: 80%;
    height: 10px;
  }

  .prgs-green {
    background-color: #72c069;
    width: 80%;
    height: 10px;
  }
}

.loreeic {
  position: absolute;
  right: 1%;
  top: 13%;
  visibility: hidden;
}

.iCard:hover {
  .loreeic {
    position: absolute;
    right: 1%;
    top: 13%;
    visibility: visible;
    font-weight: bold;
    opacity: 1;
    transition: opacity 2s ease-in;
  }
}

.card-create-btn {
  padding: 7px 20px !important;
  background: #112299 !important;
  border-radius: 4px !important;
  border-color: #112299 !important;

  &:hover {
    background-color: #112299;
  }
}

.card-style {
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  background: #fff;
}

.text-color {
  color: #112299 !important;
  font-size: 16px !important;
}

.title-body {
  margin-left: 16px;
}

.description-text {
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: 350;
  color: #585858;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1260px !important;
  }
}

@media (min-width: 768px) {
  .landingPageLoreeInteractive .col-md-3 {
    flex: 0 0 auto;
    width: 20% !important;
  }
}

#interactiveCards {
  border-radius: 16px;
  border: 1px solid #d8d8d8;
  gap: 16px;
}

#interactiveTitle {
  font-size: 16px;
  font-weight: 450;
}

#interactiveCreateBtn {
  border-radius: 8px !important;
  padding: 8px 16px !important;
  gap: 8px;
  font-weight: 450;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #0e1b7a !important;
  }
}

#interactiveFooter {
  padding-bottom: 16px !important;
  --bs-bg-opacity: 0;
}

.createIcon,
.sharedIcon,
.privateIcon,
.globalIcon {
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
}

.createIconDiv {
  border: 1px solid transparent;
  padding: 16px;
  border-radius: 12px;
  margin: 10px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #112299;
}

.privateIconDiv,
.sharedIconDiv,
.globalIconDiv {
  border: 1px solid transparent;
  padding: 16px;
  border-radius: 12px;
  margin: 10px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background-color: #eff1fe;
    color: #112299;
    border-color: #b5badf;
  }
  &:hover {
    background-color: #eff1fe;
    color: #112299;
  }
}

.left-content {
  width: 78px;
  border-right: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  min-height: 100vh;
}

#interactiveContainer {
  margin-top: 0px;
  padding-left: 0px;
}

#interactivesColumn {
  width: 100%;
}

.icon-sidebar {
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.icon-content {
  margin-left: 80px;
  padding: 20px;
  flex-grow: 1;
}

.layout-container {
  display: flex;
}

#interactiveMargin {
  margin-top: 0px;
}

#editInteractive {
  margin-top: 0px;
}
