@import '../../assets/scss/color.scss';

@keyframes roll-in {
  0% {
    top: 10px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation: pulse 1s infinite;
}

.fade-in {
  animation: fade 0.75s ease;
}

.quiz {
  min-height: 40vh;

  .progress {
    position: relative;
    transition: width 0.4s ease;
    margin-bottom: 1em;
    background: rgb(181, 181, 181);
    border-radius: 0;
    width: 100%;
    height: 1em;

    .counter {
      position: absolute;
      right: 5px;
      top: 0;
      height: 100%;
      margin: auto 0.5em;
      letter-spacing: 0.025em;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .img-fluid {
    margin: 2em auto;
    max-width: 360px;
    display: block;
  }

  .question {
    line-height: 1.35;
    margin-bottom: 0.75em;
  }
  .question img,
  .option img {
    max-width: 100%;
    height: auto;
  }
  .optionAlignment {
    line-height: normal;
    word-break: break-word;
    margin-top: 1px;
  }
  .hover {
    cursor: pointer;
  }

  .option {
    margin: 20px 0px;
    transition: all 0.25s ease;
    font-size: 16px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0px !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0px;
    }
    .formCheckInputOption {
      margin-top: auto;
    }
  }

  button {
    cursor: pointer;

    i {
      margin-left: 0.15em;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  //custom radio controls
  input[type='radio'] {
    position: absolute;
    background-color: transparent;
    border: none;
    cursor: pointer;

    & + label {
      position: relative;
      font-weight: normal;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    input[type='radio']:focus-visible {
      outline-offset: black auto 1px !important;
    }
  }

  .custom-input:checked {
    border: none !important;
    background-color: transparent !important;
  }

  .form-check-input:focus {
    box-shadow: none !important;
  }
  .form-check-input:active {
    filter: brightness(120%);
  }

  .form-check-input.is-valid {
    border: none !important;
  }

  input[type='radio']:checked:after {
    width: 8px;
    height: 8px;
    border-radius: 15px;
    top: -29px;
    left: 3px;
    position: relative;
    background-color: $color_ultramarine;
    content: '';
    display: inline-block;
    visibility: visible;
  }

  input[type='radio']:checked::before {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid $color_ultramarine;
    background-color: white;
  }

  input[type='radio']:not(:checked)::before {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid $color_ultramarine;
    background-color: white;
  }

  .dim,
  .correct {
    input[type='radio'] + label::before {
      border: 0;
      font-size: 1.2em;
      animation: 0.25s roll-in ease;
    }

    input[type='radio'] + label::after {
      display: none;
    }
  }

  .correct input[type='radio'] + label:before {
    content: '\f00C';
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 900;
    color: $positive-progress;
    position: absolute;
    left: -23px;
    top: 0px;
    height: 35px;
    width: 16px;
  }

  .dim input[type='radio'] + label:before {
    content: '\f00d';
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 900;
    color: $negative-progress;
    position: absolute;
    left: -23px;
    top: 0px;
    height: 35px;
    width: 16px;
  }

  input[type='radio']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  input[type='radio']:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }

  //end custom radio controls

  //custom checkbox controls
  input[type='checkbox'] {
    position: absolute;

    & + label {
      position: relative;
      font-weight: normal;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #000;
    }
    input[type='radio']:focus-visible {
      outline-offset: black auto 1px !important;
    }
  }

  .dim,
  .correct {
    input[type='checkbox'] + label::before {
      border: 0;
      font-size: 1.2em;
      animation: 0.25s roll-in ease;
    }

    input[type='checkbox'] + label::after {
      display: none;
    }
  }

  .correct input[type='checkbox'] + label:before {
    content: '\f00C';
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 900;
    color: $positive-progress;
    left: -23px;
    position: absolute;
    top: 0px;
    height: 35px;
    width: 16px;
  }

  .dim input[type='checkbox'] + label:before {
    content: '\f00d';
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 900;
    color: $negative-progress;
    position: absolute;
    left: -23px;
    top: 0px;
    height: 35px;
    width: 16px;
  }

  input[type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  input[type='checkbox']:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  .bottom {
    width: 90%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    div {
      flex: 1 1 70%;
      font-size: 0.9em;
    }

    .next {
      flex: 0 1 10%;
    }

    @media (max-width: 600px) {
      div,
      .next {
        flex-basis: 100%;
      }

      .next {
        margin-left: 0;
      }
    }
  }

  .get-results {
    display: block;
    margin: 2em auto;
  }

  .results {
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 40vh;

    button {
      margin-top: 1em;
    }
  }
}
.question-container {
  .option.correct-option {
    border: 2px solid $positive-progress;
    border-radius: 4px;
  }
  .option.wrong {
    border: 2px solid $negative-progress;
    border-radius: 4px;
  }
}

.custom-style {
  font-style: italic;
  color: #555;
}
