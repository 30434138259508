@import '../../assets/scss/color.scss';

.colorpicker-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.font-colorpicker {
  z-index: 2;
}

.bg-colorPicker {
  z-index: 1;
}

.custom-header-title {
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  font-weight: 400;
  margin-left: 12px !important;
}

.selected-section {
  color: $color_ultramarine !important;
}

.space-group {
  display: flex;
  margin-right: 60px;
  margin-top: 12px;
}

.space-top-input.form-control {
  border: none;
  border-radius: 0;
  margin-left: 10px;
}

.space-left-input.form-control {
  border: none;
  border-radius: 0;
}

.space-right-input.form-control {
  border: none;
  border-radius: 0;
}

.space-bottom-input.form-control {
  border: none;
  border-radius: 0;
  margin-left: 10px;
}

.space-radius-input.form-control {
  display: flex;
  width: 60px;
  padding: 5px 10px;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #8f8f8f;
  background: #fff;
}

#loree-cp2 #color-picker {
  border: 1px solid #e7e9f5;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  z-index: 100;
  padding: 0px !important;
  div:first-child > div:nth-child(2) {
    padding-left: 16px;
  }
  div:nth-child(2) > div:nth-child(2) {
    width: 36px;
    height: 36px;
    border-radius: 24px;
    margin-right: 16px;
    margin-left: 16px;
  }
  div[title='Opacity'],
  div[title='Hue'] {
    height: 13px;
    div {
      width: 14px;
      height: 14px;
      top: 2px;
    }
  }
  div:nth-child(4) {
    padding-top: 8px;
    select[title='Theme Select'],
    input {
      font-family: Lexend;
      font-size: 14px;
      font-weight: 300;
      height: 32px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      color: #585858;
    }
  }
  div:nth-child(3) {
    color: #585858 !important;
    font-size: 14px;
    font-family: Lexend;
    font-style: normal;
    padding-top: 4px;
    margin-left: 80%;
    input {
      margin-top: 5px;
      width: 45px;
      height: 31px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      color: #585858;
    }
    label {
      margin-top: 11px;
    }
  }
  button {
    background: #ffffff !important;
    border: 1px solid #e9e9e9 !important;
    border-radius: 8px !important;
    margin-right: 16px;
  }
}

.accordion-collapse .alignment-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  svg path {
    fill: $color_dorado;
  }
}

.margin-top-label,
.margin-bottom-label {
  color: $color_gray;
  font-size: 14px;
  font-weight: 350;
}

.fontColor-input,
.bgColor-input,
.borderColor-input,
.inActiveBgColor-input,
.inActivefontColor-input,
.separatorcolor-input,
.btnFontColor-input,
.btnbgColor-input,
.pbFontColor-input,
.pbBgColor-input,
.questionBgColor-input,
.optionBgColor-input,
.buttonFontColor-input,
.buttonBgColor-input,
.click_bgColor-input,
.reveal_bgColor-input,
.frontcard_bgColor-input,
.backcard_bgColor-input,
.iconcolor-input,
.imageBgColor-input,
.Area_fontColor-input,
.Area_bgColor-input,
.dropzoneBgColor-input,
.Button_fontColor-input,
.Button_bgColor-input {
  height: 34px;
  width: 138px;
  color: $color_dorado;
  font-size: 14px;
  font-weight: 350;
  font-family: Lexend;
  padding: 8px 16px;
  border: 1px solid $color-lti-frame;
  border-radius: 8px;
  &:focus-visible {
    outline: none;
  }
}

.colorpicker-container {
  display: flex;
  align-items: center;
  gap: 16px;
}
.color-picker-design-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .color-picker {
    height: 34px;
    width: 34px;
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid $color-lti-frame;
  }
}

.border-radius-input.form-control {
  height: 34px;
  width: 70px;
  border: 1px solid $color-lti-frame;
  border-radius: 8px;
  padding: 8px 16px;
  color: $color_dorado;
  font-weight: 350;
  font-size: 14px;
  font-family: Lexend;
  &:hover {
    border: 1px solid $gray-light;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid $gray-light;
  }
}

.border-radius-row {
  width: 188px;
}

.customize-container .custom-header-title .accordion-button {
  padding: 8px;
}

.border-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  .space-left-input,
  .space-top-input,
  .space-right-input,
  .space-bottom-input {
    border: 1px solid $color-lti-frame;
    border-radius: 8px;
    padding: 8px 16px;
    width: 70px;
    height: 34px;
    margin: 0;
    color: $color_dorado;
    font-weight: 350;
    font-size: 14px;
    font-family: Lexend;
    &:hover,
    &:focus {
      border: 1px solid $gray-light;
      box-shadow: none;
    }
  }
}

.dnd-container .opacity-input {
  border: 1px solid $color-lti-frame;
  border-radius: 8px;
  padding: 0 16px;
  color: $color_dorado;
  font-weight: 350;
  font-size: 14px;
  font-family: Lexend;
  &:hover,
  &:focus {
    border: 1px solid $gray-light;
    box-shadow: none;
  }
}

.border-input-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.border-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-content: center;
  .design-section-text {
    margin: 0;
  }
}

.design-section-sub-text {
  color: $color_gray;
  font-size: 14px;
  font-weight: 350;
  margin: 0;
}

.borderstyle-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

div[title='Opacity'] {
  margin-left: 16px !important;
  width: 215px;
}

div[title='Hue'] {
  border-radius: 8px;
  margin-left: 16px !important;
  max-width: 215px;
}

.invalid-color {
  border: 1px solid #c53330;
  color: #c53330;
}
