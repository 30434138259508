.external-links-tab-input {
  position: relative;
  border-radius: 4px;
  border: 1px solid #112299;
  background: #fff;
  margin-bottom: 28px;
  height: 40px;
  font-size: 14px;
  padding-left: 40px !important;
}

.external-links-video-icon {
  margin: 10px;
  position: absolute;
  z-index: 1;
}

.video-modal-container {
  input {
    font-size: 14px !important;
    font-weight: 350;
    font-style: normal;
    color: #585858;
    border: 1px solid #d8d8d8 !important;
    border-radius: 8px;
    &:focus,
    &:focus-visible {
      border: 1px solid #d8d8d8 !important;
      box-shadow: none !important;
    }
  }
}
