@import "../../assets/scss/color.scss";

.element-wrapper {
  margin: auto;
  padding: 0 33%;
  text-align: center;
  align-items: center;

  .fa-spin {
    animation-duration: 1s;
    color: $color_ultramarine !important;
  }
}
