@import '../../assets/scss/color.scss';

.lint-custom-tab-section {
  .nav-tabs {
    border-bottom: 0px !important;
    .nav-item {
      flex: none !important;
    }
    .nav-link {
      font-size: 14px;
      padding: 5px 15px !important;
      background-color: white !important;
      margin-bottom: 0 !important;
      color: #585858 !important;
      border: none !important;
      border-radius: 8px !important;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 18px;

      &:hover {
        color: $color_ultramarine !important;
      }
    }

    .nav-link.active {
      color: $color_ultramarine !important;
      border: 1px solid #eff1fe !important;
      background-color: #eff1fe !important;
      border: 8px !important;
    }
  }
  .tab-pane {
    margin-top: 20px !important;
    height: 230px !important;
  }
}
