@import '../../assets/scss/color.scss';

.interactive-lists {
  color: $black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.custom-filter-dropdown {
  width: 11%;
}

#interactiveTable {
  margin-bottom: 0px !important;
}

.lint-custom-tanStack-table {
  border-spacing: 0 10px;
  margin-bottom: 39px;
  margin-top: -10px;

  .selection-checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .selection-checkbox {
      display: inline-flex;
    }
  }

  th {
    color: $black !important;
    font-weight: normal;

    .title {
      color: #000;
      font-family: SourceSansPro-Regular;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
    .sorting-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      background-color: transparent;
      border: 0;
      padding: 0;

      .title {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        flex: 0.8;
      }

      .icon {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        flex: 0.2;
      }
    }
  }

  tbody > tr:nth-child(odd) > td,
  tbody > tr:nth-child(odd) > th {
    background-color: $white_shadow !important;
    font-size: 14px !important;
    --bs-table-bg-type: $white_shadow !important;
  }

  tbody > tr:nth-child(even) > td,
  tbody > tr:nth-child(even) > th {
    background-color: $white !important;
    font-size: 14px !important;
  }

  tbody {
    .form-check-input {
      cursor: pointer !important;
    }

    .form-check-input:checked {
      background-color: $color_ultramarine;
      border-color: $color_ultramarine;
    }

    .td-capitalize {
      text-transform: capitalize;
    }

    td {
      vertical-align: middle !important;
      word-break: 'break-word';

      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 110px;
      }
    }
    a {
      color: $black;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.interactive-list-wrapper {
  .form-group {
    margin-bottom: 0;
    position: relative;

    select.form-control {
      width: 172px;
      border: 1px solid $dim-grey;
      border-radius: 4px;
      outline: none;
      color: $black;
      font-size: 12px;
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }
    }

    select:disabled {
      cursor: default;
    }

    .arrow-down {
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid $color_dorado;
      position: absolute;
      top: 38%;
      right: 6%;
      pointer-events: none;
    }
  }
}

.search {
  border: 0;
  outline: none;
  transition: width 0.7s cubic-bezier(0, 0.795, 0, 1);
  font-size: 14px;
  width: 160px;
}
.search-icon-normal {
  position: absolute;
  right: 85%;
  top: 30%;
  &:hover {
    fill: $color_ultramarine;
  }
}
.search-icon {
  position: absolute;
  right: 6%;
  top: 17%;
}
@media (max-width: 400px) {
  .search {
    width: 150px;
    padding-right: 1.25rem;
  }
  .search-icon {
    position: absolute;
    right: 21%;
    top: 21%;
  }
}

.loree-custom-tanStack-table-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdown-icon {
    position: absolute;
    right: 7px;
    top: 10px;
  }

  .pagination {
    align-items: center;
    margin-bottom: 0;

    .disabled {
      cursor: not-allowed;

      .page-link {
        span {
          color: #8f8f8f;
        }
      }
    }
  }

  .page-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    span {
      color: #112299;
      font-size: 20px;
      margin-top: -5px;
    }
  }

  .page-link:focus {
    box-shadow: none;
  }

  .navigation,
  .current-page-info,
  .pagination-options {
    display: inline-flex;
    align-items: center;

    .dropdown-toggle-style {
      background-color: $white !important;
      color: $black !important;
      padding: 8px 16px;
      border-radius: 6px !important;
      border: 1px solid $color-lti-frame;
      background: $white !important;
      gap: 10px !important;
      font-size: 12px !important;
    }

    .dropdown-menu {
      min-width: 56px !important;
      font-size: 12px !important;
    }

    .dropdown-item {
      &:hover {
        background-color: $dropdown-hover;
        color: $color_dorado;
      }
    }

    .dropdown-toggle::after {
      content: none !important;
    }
  }

  .current-page-info {
    justify-content: center;

    .option {
      margin-right: 10px;

      .text-style {
        color: $dim-grey;
        font-size: 14px;
        font-weight: 350;
      }
    }
  }

  .pagination-options {
    justify-content: end;
    .option {
      margin-right: 10px;
      font-size: 12px;
    }
  }
}

.loree-custom-tanStack-table-pagination {
  position: fixed;
  background: $white;
  bottom: 0px;
  width: 100%;
}

#interactiveTableBody {
  overflow-y: auto;
}

.table-page {
  border: 1px solid $color-lti-frame;
  margin-top: 0px;
  border-spacing: 0;
  border-radius: 8px;
  font-family: Lexend;
  width: 100%;
  td {
    padding: 16px;
    height: 50px;
  }
  .table-page-body {
    color: $color_dorado;
    .embed-btn,
    .embed-btn:active {
      color: $color_dorado;
      border: 1px solid $color_gainsboro;
      border-radius: 8px;
      padding: 8px 16px;
      margin: 0px;
      height: 34px;
      padding: 8px 16px;
      background-color: $white;
      line-height: 18px;
      &:hover {
        background-color: $loree-frame-blue-bg;
      }
    }
    .embed-btn:focus-visible {
      box-shadow: none;
    }
    a {
      color: $color_dorado;
    }
    tr td > div {
      color: $color_dorado;
      font-weight: 350;
    }
    .form-check.form-switch {
      .form-check-input {
        width: 24px;
        height: 13px;
      }
    }
    td {
      border-bottom: 1px solid $color-lti-frame;
      min-height: 50px;
      box-sizing: border-box;
      padding: 0 16px;
    }
    tr {
      height: 50px;
      min-height: 50px;
      box-sizing: border-box;
    }
  }
  td > * {
    min-height: 34px;
  }
  .table-page-body tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  .table-page-body tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  td.table-body-cell {
    vertical-align: middle;
  }
  .table-body-cell > div,
  .table-body-cell > a,
  .table-body-cell > button {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 8px;
  }
}

.table-page .tablepage-dropdown {
  .tablepage-dropdown-toggle::after {
    display: none;
  }
  .tablepage-dropdown-toggle {
    padding: 0;
  }
  svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0px;
    border-radius: 4px;
    &:hover {
      background-color: $dropdown-hover;
    }
    a {
      text-decoration: none;
      padding: 4px 8px;
    }
    button {
      border: none;
      color: #585858;
      background-color: #fff;
      width: 100%;
      text-align: left;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
  .dropdown-menu.show {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: none;
    min-width: 128px;
    box-shadow: 0px 4px 20px 0px #58585833;
    padding: 4px 1px;
  }
  .dropdown-item a {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}

#interactiveTableHead {
  top: 0;
  z-index: 2;
  width: 100%;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

.table-body-scroll-container {
  max-height: calc(70vh - 40px);
  overflow-y: auto;
}

.table-body-scroll-container,
.row-scroll-container {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.table-body-scroll-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.table-wrapper {
  overflow: hidden;
}

.table-wrapper,
.row-table-wrapper {
  max-height: calc(70vh - 40px);
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

.table-page .table-page-head {
  th {
    background-color: $tile-grey;
    border: none;
    padding: 16px;
    color: $color-nero;
    font-size: 14px;
    font-weight: 350;
  }
}

.lint-custom-tanStack-table.table-page tbody > tr:nth-child(odd) > td,
.lint-custom-tanStack-table tbody > tr:nth-child(odd) > th {
  background-color: $white !important;
}

.tablepage-head-row {
  background-color: $tile-grey;
  .text-center {
    font-size: 14px;
    color: $color_dorado;
    font-weight: 350;
  }
  th:first-child,
  td:first-child {
    border-top-left-radius: 8px;
  }
  th:last-child,
  td:last-child {
    border-top-right-radius: 8px;
  }
}

.lint-custom-tanStack-table .tablepage-head-row {
  .title {
    color: $color_dorado;
    font-family: LExend;
    font-weight: 350;
  }
}

.interactive-list-wrapper .interactive-dropdown-container {
  .interactive-list-dropdown {
    border: 1px solid $color-lti-frame;
    border-radius: 8px;
    padding: 8px 16px;
    color: $color_dorado;
    font-weight: 350;
    font-family: Lexend;
    font-size: 14px;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid $gray-light;
    }
    &:focus-visible {
      outline: none;
      border: 1px solid $gray-light;
    }
    .text-capitalize {
      font-weight: 350;
    }
  }
  .interactive-list-dropdown[aria-expanded='true'] {
    border: 1px solid $gray-light;
  }
}

.interactive-list-wrapper {
  font-family: Lexend;
  div:has(> ul > li.interactive-list-dropdown-option) {
    border-radius: 6px;
    border: none;
    box-shadow: 0px 4px 20px 0px #58585833;
    padding: 4px 1px;
    gap: 4px;
  }
  ul:has(> li.interactive-list-dropdown-option) {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  li.interactive-list-dropdown-option:hover {
    background-color: $dropdown-hover;
    color: $color_dorado;
    border-radius: 4px;
  }
  li.interactive-list-dropdown-option {
    .interactivelist-dropdown-text:hover {
      background-color: transparent;
      color: $color_dorado;
    }
  }
  .interactive-list-dropdown {
    .tooltip-container {
      &:hover {
        background-color: none;
      }
    }
  }
  .interactive-list-heading {
    position: fixed;
    color: $color_dorado;
    font-size: 16px;
    font-weight: 450;
  }
}

.interactive-dropdown-container {
  .tooltip-container {
    color: $color_dorado;
    font-size: 14px;
    font-weight: 350;
  }
  .interactive-list-dropdown-option.selected {
    background-color: $dropdown-hover;
    .tooltip-container {
      color: $color-nero;
      font-weight: 450;
    }
  }
  .tooltip-container:hover {
    background: none;
    color: $color_dorado;
  }
  div:has(> .tooltip-container):hover {
    background: none;
  }
}

.interactive-list-wrapper {
  .interactives-searchbar {
    border: 1px solid $color-lti-frame;
    border-radius: 8px;
    padding: 8px 16px;
    height: 34px !important;
    width: 160px !important;
    color: $color_dorado;
    font-size: 14px;
    font-weight: 350;
    &:hover,
    &:focus {
      border: 1px solid $gray-light;
    }
  }
  .interactives-searchbar::placeholder {
    padding-right: 12px;
    color: $gray-medium;
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
  }
  .interactives-searchbar:focus::placeholder {
    opacity: 0;
  }
  .interactive-list-titlebar {
    height: 34px;
  }
  .search-normal-icon {
    height: 16px;
    width: 16px;
  }
  .iconActive,
  .search-normal-icon {
    cursor: pointer;
  }
  .loree-custom-tanStack-table-pagination {
    .form-control,
    .form-control::placeholder {
      color: $color_dorado;
      font-size: 14px;
      font-weight: 350;
    }
    .pagination-options {
      .dropdown-toggle-style {
        padding: 8px 16px;
      }
      .dropdown-menu.show {
        padding: 4px 1px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 350;
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 68px;
        box-shadow: 0px 4px 20px 0px #58585833;
        transform: translate(17px, 54px);
        border: 1px solid $color-lti-frame;
      }
      .dropdown-item {
        color: $color_dorado;
        font-weight: 350;
        padding: 4px 8px;
        &:hover {
          background-color: $dropdown-hover;
          color: $color_dorado;
          border-radius: 4px;
        }
      }
      .dropdown-icon {
        width: 12px;
        height: 12px;
      }
      .rowValues {
        color: $color_dorado;
        font-weight: 350;
        font-size: 14px;
      }
    }
    .pagination-toggle-style {
      display: flex;
      gap: 8px;
    }
  }
}

.loree-custom-tanStack-table-pagination
  .pagination-options
  .dropdown-toggle-style.pagination-toggle-style {
  gap: 8px;
  width: 68px;
  height: 34px;
  .dropdown-icon {
    position: relative;
    top: 0;
    right: 0;
  }
  span {
    color: $color_dorado;
    font-size: 14px;
    font-weight: 350;
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $color_gainsboro;
  }
}

.loree-custom-tanStack-table-pagination .pagination {
  .current-page-info .form-control {
    font-weight: 350;
  }
  .pagination-chevron-icon {
    height: 16px;
    width: 16px;
  }
  a.page-link {
    display: flex;
    padding: 8px;
    height: 32px;
    width: 32px;
  }
}

.lint-custom-tanStack-table .tablepage-dropdown a:hover {
  text-decoration: none;
}

#paginationOptions {
  margin-right: 7%;
}
.searchInput {
  &:focus {
    border: 1px solid #707070 !important;
  }
}
