#interactive-image:hover {
  cursor: pointer;
}

.interactive-image-PreView {
  .image-uploading, img {
      height: 220px;
      width: 100%;
      object-fit: contain !important;
  }
}

.cardHeight {
  height: 318px !important;
  overflow: auto;
  margin: 0 auto;
  position: relative;
}

.interactive-imageView {
  .image-uploading, img {
      height: 250px;
      width: 100%;
      object-fit: contain !important;
  }
  
  @media screen and (max-height: 800px){
    .image-uploading{
        width: 100%;
    }
  }
}
.interactive-textstyle {
  word-break: normal;
}
