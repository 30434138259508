@import '../../assets/scss/color.scss';

.styled-tool-tip {
  display: inline-block;
  position: relative;
  font-style: normal;
  font-weight: 400;
  line-height: 0.5;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  .tooltip-top {
    top: calc(33px * -1);
  }
  .tooltip-arrow:before {
    top: 100%;
    border-top-color: $color_ultramarine;
  }
  .tooltip-right {
    left: calc(100% + 3px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  .tooltip-arrow:before {
    left: calc(6px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: $color_ultramarine;
  }
  .tooltip-bottom {
    bottom: calc(30px * -1);
  }
  .tooltip-arrow:before {
    bottom: 100%;
    border-bottom-color: $color_ultramarine;
  }
  .tooltip-left {
    left: auto;
    right: calc(100% + 3px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  .tooltip-arrow:before {
    left: auto;
    right: calc(6px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: $color_ultramarine;
  }
}

.tool-tip-content {
  background-color: $white;
  border: 1px solid $color_ultramarine;
  color: $color_ultramarine;
  margin-top: 4px;
  font-size: 11px;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  &:before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
  }
}
.interactiveTooltipInfo .tooltip-arrow {
  display: none !important;
}

.interactiveTooltipInfo{
  background-color: #ffffff !important;
  opacity: 1 !important;
}

.interactiveTooltipInfo .tooltip-inner {
  background-color: #ffffff !important;
  color: #585858 !important;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 350;
  font-family: 'Lexend';
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  box-shadow: 0px 2px 10px rgba(28, 28, 28, 0.08);
  transform: translateX(-1px);
}

.interactiveTooltipInfo .interactivetooltiptarget {
  font-size: 14px;
  font-weight: 350;
  font-family: 'Lexend';
  background-color: #ffffff !important;
}

