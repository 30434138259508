@import '../../assets/scss/color.scss';

.preview {
  margin: 0px 0px 0px 210px !important;
  font-size: 2.1em;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
}

.prevTryAgain {
  font-size: 2.1em;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.buttonTextWrap {
  width: 100% !important;
  min-width: 10px !important;
  height: 100%;
  font-size: 10px !important;
  padding: 5px !important;
  border: none;
}

.droptarget {
  width: 100%;
  height: 60px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 4px;
  button {
    position: relative;
  }
}

.fixed-tick-circle {
  color: green;
  font-size: 18px;
  position: absolute;
  right: 25px;
  bottom: 5px;
}

.overlay-tick-circle {
  color: green;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.fixed-cross-circle {
  color: red;
  font-size: 18px;
  position: absolute;
  right: 25px;
  bottom: 5px;
}

.overlay-cross-circle {
  color: red;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.drop-btn {
  max-width: 150px !important;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5.62581px 0px;
  word-break: break-word;
}

#buttonsHolder, .dndImageContainer {
  border: 1px solid $color_ultramarine;
  padding: 10px;
  border-radius: 5px;
}

.fixedDndContainer {
  height: 360px;
  overflow: auto;
  padding: 5px;
  border: 1px solid $color_ultramarine;
  border-radius: 5px;
}

.drag img {
  width: 100%;
  height: 167px;
  object-fit: contain;
}
.toaster-alert {
  position: absolute !important;
  right: 40% !important;
  z-index: 2 !important;
  background: red !important;
}
.drop {
  height: 125px;
}

.tryAgain,
.finalResults {
  margin: 50px 0px 0px 0px;
  font-size: 2.1em;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.results {
  animation: pulse 1s infinite;
}

.animation {
  margin: 60px 0px 0px 0px !important;
}

.animation > .before,
.animation > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  margin: 180px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
    -113px -308.66667px #ff009d, -109px -287.66667px #ffb300,
    180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff,
    220px -388.66667px #99ff00, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
    -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff,
    112px -309.66667px #005eff, 69px -415.66667px #ff00a6,
    168px -100.66667px #ff004c, -244px 24.33333px #ff6600,
    97px -325.66667px #ff0066, -211px -182.66667px #00ffa2,
    236px -126.66667px #b700ff, 140px -196.66667px #9000ff,
    125px -175.66667px #00bbff, 118px -381.66667px #ff002f,
    144px -111.66667px #ffae00, 36px -78.66667px #f600ff,
    -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.animation > .after {
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white;
  }
}
@keyframes bang {
  from {
    box-shadow: 0 0 white;
  }
}

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    opacity: 0;
  }
}

// custom overlay

.dndImage {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.dndImage img {
  cursor: initial !important;
  border: none !important;
  max-width: 980px;
  width: 100%;
  display: block;
}

.dropZone {
  position: absolute;
  cursor: pointer;
}
