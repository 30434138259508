.fa-spin {
  animation-duration: 1s;
  color: #112299 !important;
}
.loader-container {
  position: absolute;
  text-align: center;

  .margin-loader.landingPageLoreeInteractive-loader.container-fluid {
    margin: 50px 10px 5px 10px;

    #interactiveCards {
      border-radius: 16px;
      gap: 10px;
      width: 100% !important;
      height: 100% !important;
      .card-img {
        background-color: #e9e9e9;
        height: 150px;
        margin: 16px;
        width:auto;
      }
      .skeleton-title-short {
        width: 150px !important;
        height: 20px;
        background: #f2f2f2;
        border-radius: 8px;
        animation: landpage-animate-loader 1.5s infinite ease-in-out;
      }
      .skeleton-title-large {
        height: 18px;
        background: #f2f2f2;
        width: 240px !important;
        border-radius: 8px;
        animation: landpage-animate-loader 1.5s infinite ease-in-out;
        margin-top: 10px;
      }
      .skeleton-title-small {
        height: 18px;
        background: #f2f2f2;
        width: 100px !important;
        border-radius: 8px;
        animation: landpage-animate-loader 1.5s infinite ease-in-out;
        margin-top: 10px;
      }
      .skeleton-title-medium {
        height: 18px;
        width: 200px !important;
        border-radius: 8px;
        background-color: #f2f2f2;
        animation: landpage-animate-loader 1.5s infinite ease-in-out;
        margin-top: 10px;
      }
      .skeleton-title-button-medium {
        height: 25px;
        width: 75px !important;
        border-radius: 8px;
        background-color: #f2f2f2;
        animation: landpage-animate-loader 1.5s infinite ease-in-out;
        margin-top: 10px;
      }
    }
  }
  @keyframes landpage-animate-loader {
    0% {
      background-color: #f1f1f1;
    }
    50% {
      background-color: #e9e9e9;
    }
    100% {
      background-color: #f1f1f1;
    }
  }
}

.table-page-loader {
  margin-top: 25px;
  padding: 30px;
  .table-page .table-page-head th {
    border-bottom: 1px solid #e9e9e9;
  }

  .table-page {
    .skeleton-title-short {
      width: 25px !important;
      height: 18px;
      background: #f2f2f2;
      border-radius: 8px;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
    }
    .skeleton-title-large {
      height: 18px;
      background: #f2f2f2;
      width: 120px !important;
      border-radius: 8px;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
      margin-left: 100px;
    }
    .skeleton-title-small {
      height: 18px;
      background: #f2f2f2;
      width: 81px !important;
      border-radius: 8px;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
      margin-top: 10px;
    }
    .skeleton-title-medium {
      height: 18px;
      width: 200px !important;
      border-radius: 8px;
      background-color: #f2f2f2;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
      margin-top: 10px;
    }
    margin-top: 75px;
  }
  .table-body-cell-loader {
    .skeleton-title-short {
      width: 25px !important;
      height: 18px;
      background: #f2f2f2;
      border-radius: 8px;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
    }
    .skeleton-title-large {
      height: 18px;
      background: #f2f2f2;
      width: 120px !important;
      border-radius: 8px;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
      margin-left: 100px;
    }
    .skeleton-title-small {
      height: 18px;
      background: #f2f2f2;
      width: 100px !important;
      border-radius: 8px;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
    }
    .skeleton-title-medium {
      height: 18px;
      width: 200px !important;
      border-radius: 8px;
      background-color: #f2f2f2;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
    }
    .skeleton-title-button {
      height: 39px;
      width: 81px !important;
      border-radius: 8px;
      background-color: #f2f2f2;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
    }
    .skeleton-title-body-short,
    .skeleton-title-body-large,
    .skeleton-title-body-medium,
    .skeleton-title-body-small {
      border-radius: 8px;
      background-color: #f2f2f2;
      animation: landpage-animate-loader 1.5s infinite ease-in-out;
    }
    .skeleton-title-body-short {
      height: 18px;
      margin-left: 100px;
      width: 75px !important;
    }
    .skeleton-title-body-large {
      height: 18px;
      margin-left: 100px;
      width: 100px !important;
    }
    .skeleton-title-body-medium {
      height: 18px;
      margin-left: 100px;
      width: 100px !important;
    }
    .skeleton-title-body-small {
      height: 18px;
      margin-left: 100px;
      width: 50px !important;
    }
  }
}

.interactive-loader {
  .skeleton-title-short {
    width: 150px !important;
    height: 20px;
    background: #f2f2f2;
    border-radius: 8px;
    animation: landpage-animate-loader 1.5s infinite ease-in-out;
  }
  .skeleton-title-small {
    width: 79px !important;
    height: 32px;
    background: #f2f2f2;
    border-radius: 8px;
    animation: landpage-animate-loader 1.5s infinite ease-in-out;
  }
  .bodyLoader {
    width: 100% !important;
    height: 256px;
    background: #f2f2f2;
    border: 1px solid #e9e9e9;
    animation: landpage-animate-loader 1.5s infinite ease-in-out;
  }
}
