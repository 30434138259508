
.flip {
  cursor: pointer;
  background-color: transparent;
}

.rotate :hover{
  transform: rotateY(180deg);
}
/* width */
::-webkit-scrollbar {
  width: 2px;
  background: #ffffff;

}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.inner {
  z-index: 1 !important;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.flip .inner.flipped {
  transform: rotateY(180deg);
}
.flip .inner .front,
.flip .inner .back {
  overflow-y: scroll !important;
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  word-break: break-word;
  p {
    padding: 0px !important;
  }
}
.flip .inner img {
  width: 100%;
  object-fit: cover;
  cursor: pointer !important;
}
.flip .inner .back {
  transform: rotateY(180deg);
}
.flip .inner .front {
  transform: rotateY(0deg);
}
.squareCard {
  position: relative;
  width: 100%;
}
.responsiveCard {
  position: relative;
  width: 100%;
}
.responsiveCard:after {
  content: "";
  display: block;
  padding-top: 100%;
}
.portrait-card-7 {
  height: 800px;
}
.portrait-card-6,
.preview-portrait-card-6 {
  height: 700px;
}
.portrait-card-4,
.preview-portrait-card-4 {
  height: 500px;
}
.portrait-card-3,
.preview-portrait-card-3 {
  height: 400px;
}
.preview-portrait-card-7 {
  height: 600px;
}
.orientation {
  user-select: none;
  width: 80%;
  min-width: 5rem !important;
  padding: 1rem 0.5rem;
  box-sizing: border-box;
  min-height: 80px;
  font-size: 11px;
  font-weight: lighter;
  text-align: center;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px !important;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
  max-width: 6rem;
  .border {
    display: inline-block;
    width: 4rem;
    height: 3rem;
    background-color: #f5f5f5;
    margin: 0 auto;
    position: relative;
  }
}

.flipcardRow {
  flex-wrap: nowrap;
  display: block;
}

@media screen and (max-width: 820px) {
  .portrait-card-6,
  .portrait-card-4 {
    height: 450px;
  }
}
