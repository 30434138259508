.carousel-caption-top {
  position: absolute;
  right: 0%;
  bottom: 20px;
  left: 0%;
  z-index: 10;
  background: rgba(6, 6, 6, 0.2);
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  top: 0;
  bottom: auto;
  transform: none;
}
.carousel-caption {
  right: 0 !important;
  bottom: 20px;
  left: 0 !important;
  width: 100%;
  background: rgba(6, 6, 6, 0.2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.carousel-caption-bottom {
  position: absolute;
  right: 0 !important;
  bottom: 20px;
  left: 0 !important;
  width: 100%;
  background: rgba(6, 6, 6, 0.2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel-view {
  .carousel-content,
  img {
    height: 500px;
  }
  .image-uploading,
  img {
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }
  @media screen and (max-height: 800px) {
    .carousel-content,
    img {
      height: 400px;
    }
    .image-uploading,
    img {
      width: auto;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.carousel-preview {
  .carousel-content,
  img {
    height: 450px;
  }
  .image-uploading,
  img {
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }
}

.carousel-inner {
  p {
    margin-bottom: 0px;
  }
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 20 !important;
  top: 30% !important;
  bottom: 55% !important;
  margin: 5px !important;
  width: 60px !important;
  height: 60px !important;
}

.videoWrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56%;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel-indicators {
  bottom: -15px !important;
  button {
    background-color: #000000 !important;
  }
}

.carousel-control-prev-top:hover,
.carousel-control-prev-top:focus,
.carousel-control-next-top:hover,
.carousel-control-next-top:focus,
.carousel-control-prev-bottom:hover,
.carousel-control-prev-bottom:focus,
.carousel-control-next-bottom:hover,
.carousel-control-next-bottom:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev-top {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.15s ease;
  z-index: 20 !important;
  top: 16% !important;
  bottom: 67% !important;
  width: 10% !important;
  left: 0;
  opacity: 0.5;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
  outline: black auto 1px !important;
}

.carousel-control-next-top {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.15s ease;
  z-index: 20 !important;
  top: 16% !important;
  bottom: 67% !important;
  width: 10% !important;
  right: 0;
  opacity: 0.5;
}

.carousel-control-next-bottom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.15s ease;
  z-index: 20 !important;
  top: 60% !important;
  bottom: 20% !important;
  width: 10% !important;
  right: 0;
  opacity: 0.5;
}

.carousel-control-prev-bottom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.15s ease;
  z-index: 20 !important;
  top: 60% !important;
  bottom: 20% !important;
  width: 10% !important;
  left: 0;
  opacity: 0.5;
}
