@import '../../../assets/scss/color.scss';


.image-container {
    width: 100%;
    text-align: center;
    border: 1px solid $color_ultramarine;
    border-radius: 5px;
    scrollbar-width: thin;
    overflow: auto;
    padding: 0px 15% 0 20%;
    height: 503px;
}

/* width */
.image-container ::-webkit-scrollbar {
    width: 2px;
    background: #ffffff;
}
  /* Track */
.image-container ::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
.image-container ::-webkit-scrollbar-thumb {
    background: #888;
}
  /* Handle on hover */
.image-container ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.centered-div {
    margin: 10px;
    width: fit-content !important;
    display: inline-block;
    position: relative;

    img {
        max-width: 980px;
        visibility: hidden;
    }
}
