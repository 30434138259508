.nav-tabs .nav-item {
    outline: none;
    overflow: hidden;
    display: flex;
    flex: 1 0 20%;
  }
  .nav-item button {
    width: 100%;
    padding: 0px 10px;
  }
  #tabpanel:focus-visible {
    outline: black auto 1px;
  }
  .nav-tabs .nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: currentColor;
  }
  .tab-header-group {
    display: flex;
    width: 100%;
  }
  .nav-tabs .nav-link:hover {
    color: currentColor;
  }
  .interactivetab {
    overflow: hidden;
    flex-wrap: nowrap !important;
    scrollbar-width: none;
    border-bottom: none !important;
  }
  .tabcontent img {
    max-width: 100%;
    height: auto;
  }
  .tabcontent {
    padding: 8px 16px;
    transition: 0.8s;
  }
  .tab-control {
    height: 100% !important;
    width: 5%;
    transition: 0.8s !important;
    padding: 0px !important;
  }
  .custom-tab-container{
    width: 90%
  }
  .tab-container {
    width: 100%
  }
  .arrow {
    color: black;
    font-size: 30px;
  }
  .li-md-tab .nav-item .nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 20%;
    color: currentColor;
  }
  .list-tab > .nav-link {
    padding: 0.8rem 1rem !important;
  }
  .li-tab {
    .nav-item .nav-link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
   }
  }
  .interactivetab {
    @media (min-width: 100px) and (max-width: 320px) {
        .nav-item {
            flex: 1 0 100% !important;
        }
    }
    @media (min-width: 320px) and (max-width: 767px) {
        .nav-item {
            flex: 1 0 49.95% !important;
        }
    }
    @media (min-width: 768px) and (max-width: 991px) {
        .nav-item {
            flex: 1 0 33.29% !important;
        }
    }
    @media (min-width: 992px) and (max-width: 1200px) {
        .nav-item {
            flex: 1 0 24.99% !important;
        }
    }
  }
  .Round {
    border-radius: 0.25rem !important;
  }
  .Round a {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .Sharp a {
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
  }
  .list-tab {
    a {
        color: inherit !important;
        background-color: none !important;
    }
    a.active {
        color: black !important;
    }
    .nav {
        background: none;
    }
  }
