/*!
 * Font-Family 
 */

@import url(https://fonts.googleapis.com/css?family=Comic+New+Courier|Arial+Black|Open+Sans|Open+Sans+Condensed|Times+New+Roman|Trebuchet+MS|Nanum+Brush+Script|Poppins|Source+Sans+Pro&display=swap);

@font-face {
    font-family: "SourceSansPro-Regular";
    src: local('SourceSansPro-Regular'), url(./SourceSansPro-Regular.ttf) format('truetype');
}

/* Brush Script MT */
@font-face {
    font-family: "Brush Script MT";
    src: url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.eot");
    src: url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.svg#Brush Script MT") format("svg");
}

/* Arial Black */
@font-face {
    font-family: "Arial Black";
    src: url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.eot");
    src: url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff") format("woff"), url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.svg#Arial Black") format("svg");
}

/* open-sans-regular */
@font-face {
    font-family: "Open Sans";
    src: url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.eot");
    src: url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.woff") format("woff"), url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.svg#Open Sans") format("svg");
}

/* Poppins-regular */
@font-face {
    font-family: "Poppins";
    src: url("//db.onlinewebfonts.com/t/2f86dc938a55788d3416b876b43a116a.eot");
    src: url("//db.onlinewebfonts.com/t/2f86dc938a55788d3416b876b43a116a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/2f86dc938a55788d3416b876b43a116a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/2f86dc938a55788d3416b876b43a116a.woff") format("woff"), url("//db.onlinewebfonts.com/t/2f86dc938a55788d3416b876b43a116a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/2f86dc938a55788d3416b876b43a116a.svg#Poppins") format("svg");
}

/* Poppins-Bold */

@font-face {
    font-family: "Poppins-Bold";
    src: url("//db.onlinewebfonts.com/t/07ecc0aa9ce268962dea7356eeff50a6.eot");
    src: url("//db.onlinewebfonts.com/t/07ecc0aa9ce268962dea7356eeff50a6.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/07ecc0aa9ce268962dea7356eeff50a6.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/07ecc0aa9ce268962dea7356eeff50a6.woff") format("woff"), url("//db.onlinewebfonts.com/t/07ecc0aa9ce268962dea7356eeff50a6.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/07ecc0aa9ce268962dea7356eeff50a6.svg#Poppins") format("svg");
}


/* Poppins-Semibold */

@font-face {
    font-family: "Poppins SemiBold";
    src: url("//db.onlinewebfonts.com/t/028b4cfdfab5d1049178e9b1eb42f49e.eot");
    src: url("//db.onlinewebfonts.com/t/028b4cfdfab5d1049178e9b1eb42f49e.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/028b4cfdfab5d1049178e9b1eb42f49e.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/028b4cfdfab5d1049178e9b1eb42f49e.woff") format("woff"), url("//db.onlinewebfonts.com/t/028b4cfdfab5d1049178e9b1eb42f49e.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/028b4cfdfab5d1049178e9b1eb42f49e.svg#Poppins SemiBold") format("svg");
}

/*Lato Bold */
@font-face {
    font-family: "Lato Bold";
    src: url("//db.onlinewebfonts.com/t/9d942e0ff1014c476713a855710ce309.eot");
    src: url("//db.onlinewebfonts.com/t/9d942e0ff1014c476713a855710ce309.eot?#iefix") format("embedded-opentype"),
        url("//db.onlinewebfonts.com/t/9d942e0ff1014c476713a855710ce309.woff2") format("woff2"),
        url("//db.onlinewebfonts.com/t/9d942e0ff1014c476713a855710ce309.woff") format("woff"),
        url("//db.onlinewebfonts.com/t/9d942e0ff1014c476713a855710ce309.ttf") format("truetype"),
        url("//db.onlinewebfonts.com/t/9d942e0ff1014c476713a855710ce309.svg#Lato") format("svg");
}

/*Lato Italic */
@font-face {
    font-family: "Lato Italic";
    src: url("//db.onlinewebfonts.com/t/9d22eea1aad6a9c8208548fa45234d85.eot");
    src: url("//db.onlinewebfonts.com/t/9d22eea1aad6a9c8208548fa45234d85.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/9d22eea1aad6a9c8208548fa45234d85.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/9d22eea1aad6a9c8208548fa45234d85.woff") format("woff"), url("//db.onlinewebfonts.com/t/9d22eea1aad6a9c8208548fa45234d85.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/9d22eea1aad6a9c8208548fa45234d85.svg#Lato") format("svg");
}

/* Lato Regular */
@font-face {
    font-family: "Lato Regular";
    src: url("//db.onlinewebfonts.com/t/e080040fab017e068ee2901a6cdb5ec4.eot");
    /* IE9*/
    src: url("//db.onlinewebfonts.com/t/e080040fab017e068ee2901a6cdb5ec4.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("//db.onlinewebfonts.com/t/e080040fab017e068ee2901a6cdb5ec4.woff2") format("woff2"),
        /* chrome、firefox */
        url("//db.onlinewebfonts.com/t/e080040fab017e068ee2901a6cdb5ec4.woff") format("woff"),
        /* chrome、firefox */
        url("//db.onlinewebfonts.com/t/e080040fab017e068ee2901a6cdb5ec4.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("//db.onlinewebfonts.com/t/e080040fab017e068ee2901a6cdb5ec4.svg#Lato Regular") format("svg");
    /* iOS 4.1- */
}