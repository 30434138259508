$LOREE_BLUE: #112299;
$LOREE_YELLOW: #f7af2f;
$LOREE_RED: #c53330;
$LOREE_SUCCESS_GREEN: #1b910c;
$LOREE_GREEN: #72c069;
$LOREE_FORMAT_BUTTON_DISABLECOLOR: #afafaf;

/** Style steet content */

.formatButton {
  cursor: pointer;
}
.historyButton {
  cursor: pointer;
  border-color: $LOREE_BLUE;
  svg {
    rect {
      stroke: $LOREE_BLUE;
    }
    path {
      fill: #000000;
    }
  }
  &:disabled {
    cursor: not-allowed;
    border-color: $LOREE_FORMAT_BUTTON_DISABLECOLOR;
    svg {
      rect {
        stroke: $LOREE_FORMAT_BUTTON_DISABLECOLOR;
      }
      path {
        fill: $LOREE_FORMAT_BUTTON_DISABLECOLOR;
      }
    }
  }
}

.formatButtonActive path {
  fill: $LOREE_BLUE;
}

.formatButtonDisable {
  cursor: not-allowed;
  pointer-events: none;
  rect {
    stroke: #707070;
  }
  path {
    fill: #707070;
  }
}
