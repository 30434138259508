//colors
$black: #000;
$white: #fff;
$white_shadow: #f8f9fb;
$black_shadow: #00000029;
$color_ultramarine: #112299;
$color_dorado: #585858;
$color_light_dorado: #a1a1a1;
$color_alto: #ecedef;
$color_gray: #909090;
$color_light_gray: #d0d0d0;
$color_dark_gray: #a7a7a7;
$border-light: #f4f4f4;
$tile-grey: #fafafa;
$dim-grey: #707070;
$ghost-white: #f8f8ff;
$modal-border: #d8d8d8;
$close-fill: #bababa;
$dark-grey: #a5a5a5;
$color_gainsboro: #d8d8d8;
$color-standard-blue: #0000ff;
$color-nero: #1c1c1c;
$color-eclipse: #404040;
$color-lti-publish: #1b910c;
$color-lti-frame: #e9e9e9;
$disabled-btn-shade: #aaaaac;
$color-palette-border: #e2e2e2;
$color-link-water: #ced4da;
$positive-progress: #367b28;
$negative-progress: #eb3223;
$shade-of-cobalt: #0c50b0;
$shade-of-wishper: #e7e7e7;
$shade-of-snow: #f9f9f9;
$shade-of-matterhorn: #565656;
$Silver-Chalice: #acacac;
$Grayish-blue: #c7cdd1;
$Anti-Flash-White: #f1f1f1;
$loree-red: #c53330;
$dropdown-hover: #f2f2f2;
$gray-medium: #8F8F8F;
$gray-light: #ABABAB;
$loree-frame-blue-bg: #F8F8F8;
